<template>
  <v-app>
    <v-app-bar app>
      <v-btn :to="{ name: 'home' }" icon>
        <v-icon>mdi-home</v-icon>
      </v-btn>

      <v-toolbar-title class="headline text-uppercase">
        My Application
      </v-toolbar-title>

      <v-spacer/>

      <template v-if="isGuest">
        <v-btn :to="{ name: 'login' }" class="mr-3" text>
          Login
        </v-btn>

        <v-btn :to="{ name: 'signup' }" text>
          Signup
        </v-btn>
      </template>

      <template v-else>
        <v-btn @click="onLogout" text>
          Logout
        </v-btn>
      </template>
    </v-app-bar>

    <v-content>
      <router-view/>
    </v-content>
  </v-app>
</template>

<script>
import { LOGOUT } from '@/store/actions.type.js'

export default {
  name: 'MainLayout',

  computed: {
    isGuest () {
      return this.$store.getters['auth/isGuest']
    }
  },

  methods: {
    onLogout () {
      this.$store.dispatch(`auth/${LOGOUT}`)
        .then(() => {
          this.$router.push({ name: 'home' })
        })
    }
  }
}
</script>

// ----
// AUTH
// ----

export const PURGE_AUTH = 'logOut'
export const SET_AUTH = 'setUser'
export const SET_ERROR = 'setError'
export const PURGE_ERRORS = 'purgeErrors'

// ----
// RANKING
// ----

export const STORE_RANKS = 'storeRanks'
export const STORE_PAGES = 'storePages'

import TokenService from './token.service.js'
import ClientService from './client.service.js'

const ApiService = {
  setTokenHeader () {
    ClientService.defaults.headers.common['Authorization'] = `Bearer ${TokenService.getToken()}`
  },

  get (path, request) {
    return ClientService.get(path, request)
  },

  post (path, request) {
    return ClientService.post(path, request)
  },

  patch (path, request) {
    return ClientService.patch(path, request)
  },

  delete (path, request) {
    return ClientService.delete(path, request)
  },

  request (data) {
    return ClientService.request(data)
  }
}

export default ApiService

import Vue from 'vue'
import Vuex from 'vuex'

import auth from './auth.module.js'
import rank from './rank.module.js'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    auth,
    rank
  }
})

export default store

<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="10" md="8">
        <v-card>
          <v-toolbar flat dark>
            <v-card-title>
              Ranking
            </v-card-title>
          </v-toolbar>

          <v-card-text>
            <v-pagination
              v-model="page"
              :length="pages"
              @input="changePage"
              :disabled="submitting"
              total-visible="10"
            />

            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Rank</th>
                    <th class="text-left">Username</th>
                    <th class="text-left">Score</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="player in ranks"
                    :key="player.username"
                  >
                    <td>{{ player.rank }}</td>
                    <td>{{ player.username }}</td>
                    <td>{{ player.score }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <v-pagination
              v-model="page"
              :length="pages"
              @input="changePage"
              :disabled="submitting"
              total-visible="10"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar.show"
      :bottom="true"
      :right="true"
      :timeout="5000"
    >
      <span style="white-space: pre;">{{ snackbar.message }}</span>
      <v-btn
        :color="snackbar.color"
        @click="snackbar.show = false"
        text
      >
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { FETCH_RANKS } from '@/store/actions.type.js'

export default {
  name: 'Ranking',

  data () {
    return {
      submitting: false,
      snackbar: {
        show: false,
        color: 'red',
        message: ''
      },
      page: 1
    }
  },

  computed: {
    ...mapGetters('rank', [
      'errors',
      'ranks',
      'pages'
    ])
  },

  methods: {
    showSnackbar (color, message) {
      this.snackbar.message = message
      this.snackbar.color = color
      this.snackbar.show = true
    },

    handleError (code) {
      if (code === 500) {
        this.showSnackbar('red', this.errors.message)
      } else {
        this.showSnackbar('red', `Error ${code}`)
      }

      this.submitting = false
    },

    changePage (page) {
      this.submitting = true
      this.$store.dispatch(`rank/${FETCH_RANKS}`, page)
        .then(() => {
          this.page = page
          this.submitting = false
        })
        .catch((code) => this.handleError(code))
    }
  }
}
</script>

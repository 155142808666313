import ApiService from '@/common/api.service.js'

import {
  FETCH_RANKS
} from './actions.type.js'

import {
  STORE_RANKS,
  STORE_PAGES,
  SET_ERROR,
  PURGE_ERRORS
} from './mutations.type.js'

const state = {
  errors: [],
  ranks: [],
  pages: 0
}

const getters = {
  errors: state => state.errors,
  ranks: state => state.ranks,
  pages: state => state.pages
}

const actions = {
  [FETCH_RANKS] ({ commit }, page = 0) {
    return ApiService.get('ranking/all', { params: { page } })
      .then(({ data, headers }) => {
        console.log(headers)
        commit(STORE_RANKS, data)
        commit(STORE_PAGES, +headers['x-pagination-page-count'])
      })
      .catch(({ response }) => {
        commit(SET_ERROR, response.data)
        return Promise.reject(response.status)
      })
  }
}

const mutations = {
  [STORE_RANKS] (state, ranks) {
    state.ranks = ranks
  },

  [STORE_PAGES] (state, pages) {
    state.pages = pages
  },

  [SET_ERROR] (state, error) {
    state.errors = error
  },

  [PURGE_ERRORS] (state) {
    state.errors = []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

export const API_BASE_URL = `${window.location.protocol}//${window.location.hostname}/rest`
export const API_VERSION = 'v1'

const ClientService = axios.create({
  baseURL: `${API_BASE_URL}/${API_VERSION}/`,
  timeout: 5000
})

Vue.use(VueAxios, ClientService)

export default ClientService

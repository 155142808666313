import Vue from 'vue'
import VueRouter from 'vue-router'
import Ranking from '@/views/Ranking'
import MainLayout from '@/layouts/MainLayout'

import store from '@/store'
import { CHECK_AUTH, FETCH_RANKS } from '@/store/actions.type.js'
import EmptyLayout from '@/layouts/EmptyLayout'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: EmptyLayout,
      children: [
        {
          path: '',
          name: 'ranking',
          component: Ranking,
          beforeEnter: (to, from, next) => {
            store.dispatch(`rank/${FETCH_RANKS}`)
              .then(() => next())
          }
        },
        {
          path: 'reset/:token',
          name: 'reset-password',
          component: () => import(/* webpackChunkName: "reset-password" */ '../views/ResetPassword.vue')
        }
      ]
    },
    {
      path: '/admin',
      component: MainLayout,
      children: [
        {
          path: 'home',
          name: 'home',
          component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
          meta: { requiresAuth: false }
        },
        {
          path: 'login',
          name: 'login',
          component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
        },
        {
          path: 'signup',
          name: 'signup',
          component: () => import(/* webpackChunkName: "signup" */ '../views/Signup.vue')
        },
        { // DO NOT USE -- this page is on another domain
          path: 'confirm/:token',
          name: 'confirm-email',
          component: () => import(/* webpackChunkName: "confirm-email" */ '../views/ConfirmEmail.vue')
        },
        {
          path: 'forgot-password',
          name: 'forgot-password',
          component: () => import(/* webpackChunkName: "forgot-password" */ '../views/ForgotPassword.vue')
        }
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  store.dispatch(`auth/${CHECK_AUTH}`)
    .then(() => {
      // send guest users to Home (ranking) if current route requires authentication
      if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters[`auth/isGuest`]) {
          next({ name: 'ranking' })
        }
      }

      next()
    })
})

export default router

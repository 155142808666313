// ----
// AUTH
// ----

export const LOGIN = 'login'
export const LOGOUT = 'logout'
export const SIGNUP = 'signup'
export const CONFIRM_EMAIL = 'confirmEmail'
export const FORGOT_PASSWORD = 'forgotPassword'
export const RESET_PASSWORD = 'resetPassword'
export const CHECK_AUTH = 'checkAuth'
export const CLEAR_ERRORS = 'clearErrors'

// ----
// RANKING
// ----

export const FETCH_RANKS = 'fetchRanks'

import ApiService from '@/common/api.service.js'
import TokenService from '@/common/token.service.js'

import {
  LOGIN,
  LOGOUT,
  SIGNUP,
  CONFIRM_EMAIL,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  CHECK_AUTH,
  CLEAR_ERRORS
} from './actions.type.js'

import {
  SET_AUTH,
  PURGE_AUTH,
  SET_ERROR,
  PURGE_ERRORS
} from './mutations.type.js'

const state = {
  errors: [],
  user: {},
  isGuest: !TokenService.getToken()
}

const getters = {
  isGuest: state => state.isGuest,
  errors: state => state.errors
}

const actions = {
  [LOGIN] ({ commit }, payload) {
    return ApiService.post('user/login', payload)
      .then(({ data }) => {
        commit(SET_AUTH, data)
        return data
      })
      .catch(({ response }) => {
        let errors = response.status === 422 ? response.data.errors : response.data
        commit(SET_ERROR, errors)
        return Promise.reject(response.status)
      })
  },

  [LOGOUT] ({ commit }) {
    commit(PURGE_AUTH)
  },

  [SIGNUP] ({ commit }, payload) {
    return ApiService.post('user/signup', payload)
      .then(({ data }) => {
        commit(PURGE_ERRORS)
        return data
      })
      .catch(({ response }) => {
        let errors = response.status === 422 ? response.data.errors : response.data
        commit(SET_ERROR, errors)
        return Promise.reject(response.status)
      })
  },

  [CONFIRM_EMAIL] ({ commit }, payload) {
    return ApiService.get('user/confirm-email', { params: payload })
      .then(({ data }) => {
        commit(SET_AUTH, data)
        return data
      })
      .catch(({ response }) => {
        let errors = response.status === 422 ? response.data.errors : response.data
        commit(SET_ERROR, errors)
        return Promise.reject(response.status)
      })
  },

  [FORGOT_PASSWORD] ({ commit }, payload) {
    return ApiService.post('user/request-password-reset', payload)
      .then(({ data }) => {
        commit(PURGE_ERRORS)
        return data
      })
      .catch(({ response }) => {
        let errors = response.status === 422 ? response.data.errors : response.data
        commit(SET_ERROR, errors)
        return Promise.reject(response.status)
      })
  },

  [RESET_PASSWORD] ({ commit }, payload) {
    return ApiService.post('user/reset-password', payload)
      .then(({ data }) => {
        commit(PURGE_ERRORS)
        return data
      })
      .catch(({ response }) => {
        let errors = response.status === 422 ? response.data.errors : response.data
        commit(SET_ERROR, errors)
        return Promise.reject(response.status)
      })
  },

  [CHECK_AUTH] ({ commit }) {
    if (TokenService.getToken()) {
      ApiService.setTokenHeader()
      ApiService.get('user')
        .then(({ data }) => {
          commit(SET_AUTH, data)
        })
        .catch(({ response }) => {
          let errors = response.status === 422 ? response.data.errors : response.data
          commit(SET_ERROR, errors)
          return Promise.reject(response.status)
        })
    } else {
      commit(PURGE_AUTH)
    }
  },

  [CLEAR_ERRORS] ({ commit }) {
    commit(PURGE_ERRORS)
  }
}

const mutations = {
  [SET_ERROR] (state, error) {
    state.errors = error
  },

  [SET_AUTH] (state, user) {
    state.isGuest = false
    state.user = user
    state.errors = []
    TokenService.saveToken(state.user.token)
  },

  [PURGE_AUTH] (state) {
    state.isGuest = true
    state.user = {}
    state.errors = []
    TokenService.destroyToken()
  },

  [PURGE_ERRORS] (state) {
    state.errors = []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
